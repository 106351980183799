import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAUu9WMY5qLl4_CL7Qa_qOfyiAXmO1gOYA',
  authDomain: 'mi-users.firebaseapp.com',
  projectId: 'mi-users',
  storageBucket: 'mi-users.appspot.com',
  messagingSenderId: '897872957785',
  appId: '1:897872957785:web:a2111d1931889813b43c48'
};

const app = initializeApp(firebaseConfig);

export default app;
