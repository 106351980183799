import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import GoogleIcon from '@mui/icons-material/Google';
import { Alert, LinearProgress } from '@mui/material';

import {
  registerUser,
  validatePassword,
  PasswordPolicy,
  logIn,
  getUserSetup,
  saveUserSetup,
  validateEmail
} from '../../api/userAuthApi';

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider
} from 'firebase/auth';
import app from '../../../firebase';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as userAuthActions from '../../redux/actions/userAuthActions';

import './index.css';

function PropertyUserRegister({ webSocket, siteConfig, authUser, actions }) {
  const setUpKey = useQueryParam('accessKey', null);

  const [creds, setCreds] = useState(null);
  const [showPassword, setShowPassword] = useState(null);
  const [error, setError] = useState('');

  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const microsoftProvider = new OAuthProvider('microsoft.com');
  const facebookProvider = new FacebookAuthProvider();
  const twitterProvider = new TwitterAuthProvider();

  useEffect(() => {
    if (setUpKey) {
      getUserSetup(setUpKey).then((result) =>
        setCreds(
          result && result.Email
            ? { ...result, SetupKey: setUpKey }
            : { invalid: 'The access key you provided is not valid.' }
        )
      );
    } else {
      //setCreds({ invalid: 'Please provide an access key.' });
    }
  }, [setUpKey]);

  useEffect(() => {
    if (creds && creds.registered) {
      if (
        authUser &&
        authUser.User &&
        authUser.User.Token &&
        authUser.PropertyList &&
        authUser.PropertyList.length
      ) {
        if (siteConfig.id === 'mi') {
          navigate(
            '/property-events?property=' + authUser.PropertyList[0].ProfileKey
          );
        } else {
          navigate(
            '/property-setup?property=' + authUser.PropertyList[0].ProfileKey
          );
        }
      } else {
        setCreds({
          invalid:
            'You do not have access to any properties. Please contact support@movingin.com for help.'
        });
      }
    }
  }, [authUser]);

  // useEffect(() => {
  //   const unregisterAuthObserver = firebase
  //     .auth()
  //     .onAuthStateChanged(function (user) {
  //       if (user) {
  //         console.log(user);
  //       }
  //     });
  //   return unregisterAuthObserver;
  // }, []);

  const handleGoogleSignup = async (e) => {
    e.preventDefault();
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      setError(error.message);

      console.log('Error:', error.message);
    }
  };

  const handleMicrosoftSignup = async (e) => {
    e.preventDefault();
    try {
      await signInWithPopup(auth, microsoftProvider);
    } catch (error) {
      setError(error.message);
      console.log('error: ', error.message);
    }
  };

  const handleFacebookSignup = async (e) => {
    e.preventDefault();
    try {
      await signInWithPopup(auth, facebookProvider);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleTwitterSignup = async (e) => {
    e.preventDefault();
    try {
      await signInWithPopup(auth, twitterProvider);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="page-container property-user-registration">
      <div className="page">
        <div className="inner">
          <h1>
            Welcome to{' '}
            <img
              className="logo"
              alt={siteConfig.brand}
              src={withPrefix(siteConfig.logo)}
            />
          </h1>

          {!authUser || !authUser.User || !authUser.User.Token ? (
            creds ? (
              creds.invalid ? (
                <Alert severity="error">{creds.invalid}</Alert>
              ) : (
                <>
                  <p>
                    To access your {siteConfig.brand} Dashboard, please create
                    an account below.
                  </p>

                  <div className="registration-form-container">
                    {/* <Button
              className="microsoft-button"
              variant="outlined"
              onClick={handleMicrosoftSignup}>
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg"
                alt="Microsoft"
              />{' '}
              Login with Microsoft
            </Button>

            <Button
              className="google-button"
              variant="outlined"
              onClick={handleGoogleSignup}>
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google"
              />{' '}
              Login with Google
            </Button> */}

                    {/* <Button
              className="facebook-button"
              variant="outlined"
              onClick={handleFacebookSignup}>
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg"
                alt="Facebook"
              />{' '}
              Login with Facebook
            </Button>

            <Button
              className="twitter-button"
              variant="outlined"
              onClick={handleTwitterSignup}>
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/twitter.svg"
                alt="Twitter"
              />{' '}
              Login with Twitter
            </Button> */}

                    <div className="registration-form">
                      {/* <p>-or-</p>

              <p>Register with your email:</p> */}

                      <TextField
                        value={creds.Email}
                        required
                        label="Your Email"
                        onChange={(event) =>
                          setCreds({ ...creds, Email: event.target.value })
                        }
                        disabled
                        type="email"
                        fullWidth
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off'
                          }
                        }}
                      />

                      <TextField
                        value={creds.Password}
                        label={'Create a Password'}
                        type={showPassword ? 'text' : 'password'}
                        required
                        onChange={(event) =>
                          setCreds({ ...creds, Password: event.target.value })
                        }
                        helperText={PasswordPolicy}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off'
                          }
                        }}
                        fullWidth
                      />

                      <div className="save-button">
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={
                            !creds ||
                            !creds.Email ||
                            !creds.Password ||
                            !validateEmail(creds.Email) ||
                            !validatePassword(creds.Password) ||
                            creds.saving
                          }
                          onClick={() => {
                            if (
                              creds.Email &&
                              creds.Password &&
                              validateEmail(creds.Email) &&
                              validatePassword(creds.Password)
                            ) {
                              saveUserSetup({ ...creds }).then((result) =>
                                logIn(creds.Email, creds.Password).then(
                                  (result) => {
                                    if (
                                      result &&
                                      result.User &&
                                      result.User.Token &&
                                      result.PropertyList &&
                                      result.PropertyList.length
                                    ) {
                                      setCreds({ ...creds, registered: true });
                                      actions.userLoggedIn(result);
                                    } else {
                                      setCreds({
                                        invalid:
                                          'Invalid access code. Please contact support@movingin.com for assistance.'
                                      });
                                    }
                                  }
                                )
                              );
                            }
                          }}>
                          {creds && creds.saving
                            ? 'Saving...'
                            : 'Save and Continue'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )
            ) : (
              <LinearProgress />
            )
          ) : (
            <p style={{ marginTop: '20px' }}>
              You are logged in as {authUser.User.Email}.<br />
              <br />
              <Button
                variant="outlined"
                onClick={() => {
                  actions.logOutUser();
                  actions.pageLoading(true, 'Logging out...');
                  window.location.reload();
                }}>
                Log Out
              </Button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      logOutUser: bindActionCreators(userAuthActions.logOutUser, dispatch),
      userLoggedIn: bindActionCreators(userAuthActions.userLoggedIn, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyUserRegister);
